import React from 'react'
import './style.css'
import { RiFileCopyLine } from 'react-icons/ri'

export default function Token() {
    return (
        <div id='Services' className='Token'>
            <span>
                <h2 data-aos="fade">Tokenomics</h2>
                <p data-aos="fade">DonPepe aims to build a gaming platform and foster the largest community on TON</p>
                <div data-aos="fade" className='Industry_Con'>
                    <h3>Buy $DonPepe</h3>
                    <div className='FlexCon' />
                    <div className='Industry_Con1'>
                        <h4>0x6942040b8h765t7thfcjh3ioj4h201755d67ac89</h4>
                        <h5>0x69420....d67ac89</h5>
                        <div className='Industry_Con_Icon' onClick={() => navigator.clipboard.writeText("0x6942040b8h765t7thfcjh3ioj4h201755d67ac89")}>
                            <RiFileCopyLine size={18} />
                        </div>
                    </div>
                </div>
                <div data-aos="fade" className='Industry_Card_Con'>
                    <div className='Industry_Card'>
                        <div className='Industry_Card_Con1' />
                        <div className='Industry_Card_Txt1'>Taxes</div>
                        <div className='Industry_Card_Txt2'>0/0</div>
                    </div>
                    <div className='Industry_Card Industry_Card1'>
                        <div className='Industry_Card_Con2' />
                        <div className='Industry_Card_Txt1'>Contract</div>
                        <div className='Industry_Card_Txt2'>Renounced</div>
                    </div>
                    <div className='Industry_Card'>
                        <div className='Industry_Card_Con3' />
                        <div className='Industry_Card_Txt1'>LIQUIDITY</div>
                        <div className='Industry_Card_Txt2'>Burned</div>
                    </div>
                    <div className='Industry_Card Industry_Card1'>
                        <div className='Industry_Card_Con4' />
                        <div className='Industry_Card_Txt1'>Total Supply</div>
                        <div className='Industry_Card_Txt2'>100000000</div>
                    </div>
                </div>
            </span>
            <svg className='Token_Svg' preserveAspectRatio="xMidYMid slice" viewBox="10 10 80 80">
                {/* <path fill="#f38254" className="out-top" d="M37-5C25.1-14.7,5.7-19.1-9.2-10-28.5,1.8-32.7,31.1-19.8,49c15.5,21.5,52.6,22,67.2,2.3C59.4,35,53.7,8.5,37-5Z" />
                <path fill="#ef6a56" className="in-top" d="M20.6,4.1C11.6,1.5-1.9,2.5-8,11.2-16.3,23.1-8.2,45.6,7.4,50S42.1,38.9,41,24.5C40.2,14.1,29.4,6.6,20.6,4.1Z" />
                <path fill="#f9a450" className="out-bottom" d="M105.9,48.6c-12.4-8.2-29.3-4.8-39.4.8-23.4,12.8-37.7,51.9-19.1,74.1s63.9,15.3,76-5.6c7.6-13.3,1.8-31.1-2.3-43.8C117.6,63.3,114.7,54.3,105.9,48.6Z" />
                <path fill="#fcb44e" className="in-bottom" d="M102,67.1c-9.6-6.1-22-3.1-29.5,2-15.4,10.7-19.6,37.5-7.6,47.8s35.9,3.9,44.5-12.5C115.5,92.6,113.9,74.6,102,67.1Z" /> */}
                <path fill="#149187" className="out-top" d="M37-5C25.1-14.7,5.7-19.1-9.2-10-28.5,1.8-32.7,31.1-19.8,49c15.5,21.5,52.6,22,67.2,2.3C59.4,35,53.7,8.5,37-5Z" />
                <path fill="#117a72" className="in-top" d="M20.6,4.1C11.6,1.5-1.9,2.5-8,11.2-16.3,23.1-8.2,45.6,7.4,50S42.1,38.9,41,24.5C40.2,14.1,29.4,6.6,20.6,4.1Z" />
                <path fill="#1abdb1" className="out-bottom" d="M105.9,48.6c-12.4-8.2-29.3-4.8-39.4.8-23.4,12.8-37.7,51.9-19.1,74.1s63.9,15.3,76-5.6c7.6-13.3,1.8-31.1-2.3-43.8C117.6,63.3,114.7,54.3,105.9,48.6Z" />
                <path fill="#1dd4c6" className="in-bottom" d="M102,67.1c-9.6-6.1-22-3.1-29.5,2-15.4,10.7-19.6,37.5-7.6,47.8s35.9,3.9,44.5-12.5C115.5,92.6,113.9,74.6,102,67.1Z" />
            </svg>
        </div>
    )
}
