import React from 'react'
import './style.css'
import { RiArrowDownSLine, RiArrowRightSLine, RiArrowUpSLine } from "react-icons/ri";

export default function Graph() {
  return (
    <div className='Graph'>
      <h6>Our Roadmap</h6>
      <span>
        <div className='Graph_Con1'>
          <div className='Graph_Con1_Left'>
            <div className='Graph_Con1_Left_Con'>
              <h4>TON Game<br /><b>Play & Earn</b></h4>
            </div>
            <div className='Graph_Con1_Left_Arrow_Down'>
              <RiArrowDownSLine className='Graph_Con1_Left_Arrow_Down_Icon' size={23} color='#000' />
            </div>
            <div className='Graph_Con1_Left_Con1'>
              <h4>Earn coin</h4>
              <div className='Graph_Con1_Left_Arrow_Right_Down'>
                <RiArrowDownSLine className='Graph_Con1_Left_Arrow_Right_Down_Icon' size={23} color='#000' />
              </div>
            </div>
            <div className='Graph_Con1_Left_Arrow_Down'>
              <RiArrowDownSLine className='Graph_Con1_Left_Arrow_Down_Icon' size={23} color='#000' />
            </div>
            <div className='Graph_Con1_Left_Inner'>
              <div className='Graph_Con1_Left_Con1'><h4>Earn Energy</h4></div>
              <div className='Graph_Con1_Left_Arrow_Right'>
                <RiArrowRightSLine className='Graph_Con1_Left_Arrow_Right_Icon' size={23} color='#000' />
              </div>
              <div className='Graph_Con1_Left_Con2'><h4>Play & Earn <b>coupons</b></h4></div>
            </div>
          </div>
          <div className='Graph_Con1_Right'>
            <div className='Graph_Con1_Left_Con2'>
              <h4>Buy meme coin and Hold</h4>
            </div>
          </div>
        </div>
        <div className='Graph_Con2'>
          <div className='Graph_Con2_Left'>
            <div className='Graph_Con2_Down_Left_Arrow' />
            <div className='Graph_Con2_Down_Arrow'>
              <RiArrowDownSLine className="Graph_Con2_Down_Arrow_Icon" size={23} color='#000' />
            </div>
          </div>
          <div className='Graph_Con2_Middle'>
            <div className='Graph_Con2_Middle_Con'>
              <h3>Game</h3>
            </div>
            <div className='Graph_Con2_Middle_Con1'>
              <h2>Don Pepe</h2>
              <div className='Graph_Con2_Middle_Con1_Con'>
                <div className='Graph_Con2_Middle_Con1_Arrow'></div>
                <div className='Graph_Con1_Left_Con2'>
                  <h4>2 billion meme coins</h4>
                </div>
                <div className='Graph_Con2_Middle_Con1_Arrow1'></div>
                <div className='Graph_Con1_Left_Con2'>
                  <h4>100% liquidity</h4>
                </div>
              </div>
            </div>
            <div className='Graph_Con2_Middle_Con2'>
              <h3>Meme<br />Coin</h3>
              <div className='Graph_Con2_Middle_Con2_Up_Arrow'>
                <RiArrowUpSLine className="Graph_Con2_Middle_Con2_Up_Arrow_Icon" size={23} color='#000' />
              </div>
              <div className='Graph_Con2_Middle_Con2_Down_Arrow'>
                <RiArrowDownSLine className="Graph_Con2_Middle_Con2_Down_Arrow_Icon" size={23} color='#000' />
              </div>
            </div>
          </div>
        </div>
        <div className='Graph_Con3'>
          <div className='Graph_Con3_Left_Con'>
            <h4>Burn coins & <b>Earn meme coins</b></h4>
          </div>
          <div className='Graph_Con3_Bottom'>
            <div className='Graph_Con3_Bottom_Arrow'>
              <RiArrowRightSLine className='Graph_Con3_Bottom_Arrow_Icon' size={23} color='#000' />
            </div>
            <div className='Graph_Con3_Bottom_Con'>
              <h4>Trade in DEX & <b>Earn profit</b></h4>
            </div>
          </div>
        </div>
      </span>
      <svg className='Graph_SVG' preserveAspectRatio="xMidYMid slice" viewBox="10 10 80 80">
        <path fill="#149187" className="out-top" d="M37-5C25.1-14.7,5.7-19.1-9.2-10-28.5,1.8-32.7,31.1-19.8,49c15.5,21.5,52.6,22,67.2,2.3C59.4,35,53.7,8.5,37-5Z" />
        <path fill="#117a72" className="in-top" d="M20.6,4.1C11.6,1.5-1.9,2.5-8,11.2-16.3,23.1-8.2,45.6,7.4,50S42.1,38.9,41,24.5C40.2,14.1,29.4,6.6,20.6,4.1Z" />
        <path fill="#1abdb1" className="out-bottom" d="M105.9,48.6c-12.4-8.2-29.3-4.8-39.4.8-23.4,12.8-37.7,51.9-19.1,74.1s63.9,15.3,76-5.6c7.6-13.3,1.8-31.1-2.3-43.8C117.6,63.3,114.7,54.3,105.9,48.6Z" />
        <path fill="#1dd4c6" className="in-bottom" d="M102,67.1c-9.6-6.1-22-3.1-29.5,2-15.4,10.7-19.6,37.5-7.6,47.8s35.9,3.9,44.5-12.5C115.5,92.6,113.9,74.6,102,67.1Z" />
      </svg>
    </div>
  )
}
