import React from 'react'
import './style.css'
import { RiDiscordFill, RiFacebookFill, RiInstagramLine, RiTwitterXFill } from 'react-icons/ri'
import { useTypewriter } from 'react-simple-typewriter'

export default function Hero() {
    const [text] = useTypewriter({
        words: ['Gaming ', 'Earnings ', '100x Rewards '],
        loop: 0,
    })
    return (
        <div id='Hero' className='Hero1'>
            <span>
                <div className='Hero1_Social_Con'>
                    <div style={{backgroundColor:"#1877F2"}} className='Hero1_Social'>
                        <RiFacebookFill color='#fff' size={25} />
                    </div>
                    <a href='https://www.instagram.com/don_pepe_don/' target='_blank' style={{background:"linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"}} className='Hero1_Social'>
                        <RiInstagramLine color='#fff' size={25} />
                    </a>
                    {/* <div className='Hero1_Social'>
                        <RiDiscordFill color='#fff' size={25} />
                    </div>
                    <div className='Hero1_Social'>
                        <RiTwitterXFill color='#fff' size={25} />
                    </div> */}
                    <div className='FlexCon' />
                    {/* <div className='Hero1_Social'>
                        <RiFacebookFill color='#fff' size={25} />
                    </div>
                    <div className='Hero1_Social'>
                        <RiInstagramLine color='#fff' size={25} />
                    </div> */}
                    <div style={{backgroundColor:"#7289da"}} className='Hero1_Social'>
                        <RiDiscordFill color='#fff' size={25} />
                    </div>
                    <div className='Hero1_Social'>
                        <RiTwitterXFill color='#fff' size={25} />
                    </div>
                </div>
                <div data-aos="fade-up" className='Hero1_Img'></div>
                <div className='Hero1_Txt' data-aos="fade-up" data-aos-delay="300">
                    <h1>DonPepe</h1>
                    <p>DonPepe Universe: {text}</p>
                    <div className='Hero1_Social1'>
                        <div style={{backgroundColor:"#1877F2"}} className='Hero1_Social'>
                            <RiFacebookFill color='#fff' size={25} />
                        </div>
                        <a href='https://www.instagram.com/don_pepe_don/' target='_blank' style={{background:"linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"}} className='Hero1_Social'>
                            <RiInstagramLine color='#fff' size={25} />
                        </a>
                        <div style={{backgroundColor:"#7289da"}} className='Hero1_Social'>
                            <RiDiscordFill color='#fff' size={25} />
                        </div>
                        <div className='Hero1_Social'>
                            <RiTwitterXFill color='#fff' size={25} />
                        </div>
                    </div>
                    <button>BUY NOW</button>
                </div>
            </span>
            <svg className='Hero1_Svg' preserveAspectRatio="xMidYMid slice" viewBox="10 10 80 80">
                <path fill="#149187" className="out-top" d="M37-5C25.1-14.7,5.7-19.1-9.2-10-28.5,1.8-32.7,31.1-19.8,49c15.5,21.5,52.6,22,67.2,2.3C59.4,35,53.7,8.5,37-5Z" />
                <path fill="#117a72" className="in-top" d="M20.6,4.1C11.6,1.5-1.9,2.5-8,11.2-16.3,23.1-8.2,45.6,7.4,50S42.1,38.9,41,24.5C40.2,14.1,29.4,6.6,20.6,4.1Z" />
                <path fill="#1abdb1" className="out-bottom" d="M105.9,48.6c-12.4-8.2-29.3-4.8-39.4.8-23.4,12.8-37.7,51.9-19.1,74.1s63.9,15.3,76-5.6c7.6-13.3,1.8-31.1-2.3-43.8C117.6,63.3,114.7,54.3,105.9,48.6Z" />
                <path fill="#1dd4c6" className="in-bottom" d="M102,67.1c-9.6-6.1-22-3.1-29.5,2-15.4,10.7-19.6,37.5-7.6,47.8s35.9,3.9,44.5-12.5C115.5,92.6,113.9,74.6,102,67.1Z" />
            </svg>
        </div>
    )
}
