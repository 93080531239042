import React from 'react'
import './style.css'

export default function Story() {
    return (
        <div id='Product' className='Story'>
            <span>
                <h2 data-aos="fade">Our Story</h2>
                <div data-aos="fade" className='Story_Con'>
                    <p>In a distant land, there lives a peaceful community of frogs known as the Pepes. One day, a villainous force descended upon their swamp, capturing and chaining the Pepes in deep underground dungeons. These dungeons are filled with dangerous insects and traps, making escape nearly impossible.</p>
                    <p>But hope is not lost, as Don Pepe, a fierce and determined dart frog with a poisonous bite sets out on a mission to rescue his fellow Pepes. Armed with his trusty axe and a strong will, Don Pepe must dig through the earth, defeat the villain’s minions, and unlock the cages where the Pepes are held captive.</p>
                    {/* <div className='Story_Con1'>
                        <p>dart frog with a poisonous bite sets out on a mission to rescue his fellow Pepes. Armed with his trusty axe and a strong will, Don Pepe must dig through the earth, defeat the villain’s minions, and unlock the cages where the Pepes are held captive.</p>
                        <button>Join Don Pepe's Community</button>
                    </div> */}
                </div>
                <div data-aos="fade-up" className='Story_Con2'>
                    <p>In a distant land, there lives a peaceful community of frogs known as the Pepes. One day, a villainous force descended upon their swamp, capturing and chaining the Pepes in deep underground dungeons. These dungeons are filled with dangerous insects and traps, making escape nearly impossible. But hope is not lost, as Don Pepe, a fierce and determined dart frog with a poisonous bite sets out on a mission to rescue his fellow Pepes. Armed with his trusty axe and a strong will, Don Pepe must dig through the earth, defeat the villain’s minions, and unlock the cages where the Pepes are held captive.</p>
                    {/* <button>Join Don Pepe's Community</button> */}
                </div>
                <button>Join Don Pepe's Community</button>
            </span>
        </div>
    )
}
