import React from 'react'
import './style.css'
import Img1 from '../assets/meme-1.webp'
import Img2 from '../assets/meme-2.webp'
import Img3 from '../assets/meme-3.webp'
import Img4 from '../assets/meme-4.webp'
import Img5 from '../assets/meme-5.webp'
import Img6 from '../assets/meme-6.webp'
import Img7 from '../assets/meme-7.webp'
import Img8 from '../assets/meme-8.webp'
import Img9 from '../assets/meme-9.webp'
import Img10 from '../assets/meme-10.webp'
import Img11 from '../assets/meme-11.webp'
import Img12 from '../assets/meme-12.webp'
import Img13 from '../assets/meme-13.gif'

export default function Meme() {
  return (
    <div className='Meme'>
      <span>
        <h2 data-aos="fade">Don Pepe Memes</h2>
        {/* <svg viewBox="0 0 1440 490">
          <path id="curve" d="M0,294L120,310.3C240,327,480,359,720,359.3C960,359,1200,327,1440,310.3C1680,294,1920,294,2160,269.5C2400,245,2640,196,2880,163.3C3120,131,3360,114,3600,147C3840,180,4080,261,4320,318.5C4560,376,4800,408,5040,424.7C5280,441,5520,441,5760,392C6000,343,6240,245,6480,236.8C6720,229,6960,310,7200,302.2C7440,294,7680,196,7920,187.8C8160,180,8400,261,8640,302.2C8880,343,9120,343,9360,351.2C9600,359,9840,376,10080,318.5C10320,261,10560,131,10800,73.5C11040,16,11280,33,11520,49C11760,65,12000,82,12240,89.8C12480,98,12720,98,12960,114.3C13200,131,13440,163,13680,220.5C13920,278,14160,359,14400,400.2C14640,441,14880,441,15120,383.8C15360,327,15600,212,15840,138.8C16080,65,16320,33,16560,40.8C16800,49,17040,98,17160,122.5L17280,147L17280,490L17160,490C17040,490,16800,490,16560,490C16320,490,16080,490,15840,490C15600,490,15360,490,15120,490C14880,490,14640,490,14400,490C14160,490,13920,490,13680,490C13440,490,13200,490,12960,490C12720,490,12480,490,12240,490C12000,490,11760,490,11520,490C11280,490,11040,490,10800,490C10560,490,10320,490,10080,490C9840,490,9600,490,9360,490C9120,490,8880,490,8640,490C8400,490,8160,490,7920,490C7680,490,7440,490,7200,490C6960,490,6720,490,6480,490C6240,490,6000,490,5760,490C5520,490,5280,490,5040,490C4800,490,4560,490,4320,490C4080,490,3840,490,3600,490C3360,490,3120,490,2880,490C2640,490,2400,490,2160,490C1920,490,1680,490,1440,490C1200,490,960,490,720,490C480,490,240,490,120,490L0,490Z">
          </path>
          <text width="1440" fontSize={70}>
            <textPath href="#curve">Dangerous Curves Ahead</textPath>
          </text>
        </svg> */}
        <button data-aos="fade">Join Our Community</button>
        <div data-aos="fade" className='Meme_Con'>
          <img className='Meme_Img' src={Img13}/>
          <img className='Meme_Img1' src={Img12}/>
          <img className='Meme_Img2' src={Img3}/>
          <img className='Meme_Img1' src={Img4}/>
          <img className='Meme_Img2' src={Img5}/>
          <img className='Meme_Img1' src={Img6}/>
          <img className='Meme_Img' src={Img7}/>
          <img className='Meme_Img1' src={Img8}/>
          <img className='Meme_Img2' src={Img9}/>
          <img className='Meme_Img1' src={Img10}/>
          <img className='Meme_Img' src={Img11}/>
          <img className='Meme_Img1' src={Img1}/>
        </div>
      </span>
    </div>
  )
}