import React from 'react'
import './style.css'

export default function Footer() {
  return (
    <div className='Footer'>
        {/* <div className='Footer_Con1'>
            <span>
                <h2>TalentXWorks</h2>
                <div className='Footer_Title'>
                    <h3>Contact</h3>
                    <h3></h3>
                    <h3>Quick Links</h3>
                    <h3>Follow</h3>
                </div>
                <div className='Footer_Body'>
                    <div><p>Demo street, City<br/>Country - 123456<br/><br/>General Inquiries:<br/>9876543210</p></div>
                    <div><p>Sales:<br/>demo@gmail.com<br/><br/>Customer Care:<br/>demo@gmail.com</p></div>
                    <div><a href='https://google.com' target='_blank'>Terms & Conditions</a><br/><br/><a href='https://google.com' target='_blank'>Privacy Policy</a></div>
                    <div><p>Sign up to get the latest news on our product.</p></div>
                    <div className='Footer_Social_Con'><a href='https://google.com' target='_blank' className='Footer_Social'>LINKEDIN</a><br/><a href='https://google.com' target='_blank' className='Footer_Social'>FACEBOOK</a><br/><a href='https://google.com' target='_blank' className='Footer_Social'>INSTAGRAM</a></div>
                </div>
            </span>
        </div> */}
        <div className='Footer_Con2'>
            © 2024 Don Pepe. All rights reserved
        </div>
    </div>
  )
}
