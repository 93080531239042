import './App.css';
import Aos from "aos";
import "aos/dist/aos.css";

import Footer from './layout/footer';
import Header from './layout/header';
import Choose from './pages/choose';
import Enquiry from './pages/enquiry';
import Hero from './screen/hero';
import Industry from './pages/industry';
import Product from './pages/product';
import Services from './pages/services';
import Story from './screen/story';
import Token from './screen/token';
import Graph from './screen/graph';
import Meme from './screen/meme';
import { useEffect } from 'react';

function App() {
  
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="App">
      <Header/>
      <Hero/>
      <Story/>
      <Graph/>
      {/* <Product/> */}
      {/* <Services/> */}
      {/* <Industry/> */}
      <Meme/>
      <Token/>
      <Enquiry/>
      <Footer/>
    </div>
  );
}

export default App;
