import React, { useEffect, useState } from 'react'
import './style.css'

export default function Header() {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  return (
    <div className={'Header'}>
        <span>
            <h1>DonPepe</h1>
            <ul>
                <li onClick={()=>document.getElementById('Hero').scrollIntoView({behavior:"smooth"})}>Home</li>
                <li onClick={()=>document.getElementById('Product').scrollIntoView({behavior:"smooth"})}>Story</li>
                <li onClick={()=>document.getElementById('Services').scrollIntoView({behavior:"smooth"})}>Tokenomics</li>
                <li onClick={()=>document.getElementById('Join').scrollIntoView({behavior:"smooth"})}>Join Us</li>
            </ul>
            <button>BUY NOW</button>
        </span>
    </div>
  )
}
