import React from 'react'
import './style.css'
import { RiDiscordFill, RiFacebookFill, RiInstagramLine, RiTwitterXFill } from 'react-icons/ri'
import Dex from '../assets/dex.svg'

export default function Enquiry() {
    return (
        <div id='Join' className='Enquiry'>
            <span>
                <div className='Enquiry_Con_Img' />
                <h2 data-aos="fade">Join Us</h2>
                <div className='Hero1_Social2'>
                    <div className='Hero1_Social'>
                        <img src={Dex} />
                    </div>
                    <div style={{ backgroundColor: "#1877F2" }} className='Hero1_Social'>
                        <RiFacebookFill color='#fff' size={25} />
                    </div>
                    <a href='https://www.instagram.com/don_pepe_don/' target='_blank' style={{ background: "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)" }} className='Hero1_Social'>
                        <RiInstagramLine color='#fff' size={25} />
                    </a>
                    <div style={{ backgroundColor: "#7289da" }} className='Hero1_Social'>
                        <RiDiscordFill color='#fff' size={25} />
                    </div>
                    <div className='Hero1_Social'>
                        <RiTwitterXFill color='#fff' size={25} />
                    </div>
                </div>
                {/* <p data-aos="fade">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p> */}
                {/* <div data-aos="fade" className='Enquiry_Con'>
                    <div className='Enquiry_Con_Icon'>
                        <BiLogoTelegram/>
                    </div>
                    <div className='Enquiry_Con_Icon Enquiry_Con_Icon1'>
                        <BiLogoInstagramAlt/>
                    </div>
                    <div className='Enquiry_Con_Icon Enquiry_Con_Icon2'>
                        <BiLogoFacebook/>
                    </div>
                    <div className='Enquiry_Con_Icon Enquiry_Con_Icon3'>
                        <BiLogoDiscord/>
                    </div>
                    <div className='Enquiry_Con_Icon Enquiry_Con_Icon4'>
                        <BiLogoBitcoin/>
                    </div>
                </div> */}
            </span>
        </div>
    )
}
